import { createTheme } from '@material-ui/core/styles';

export const themeColors = {
  primary: '#f0b90b',
  secondary: '#B71C1C',
  success: '#0ECB81',
  error: '#F6465D',
  info: 'rgb(22, 177, 255)',
};

const theme = createTheme({
  palette: {
    type: 'dark',
    tertiary: {
      main: '#222222',
    },
    orders: {
      buy: '#0ECB81',
      sell: '#F6465D',
    },
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.secondary,
    },
    background: {
      default: '#292929',
      paper: '#222222',
    },
    success: {
      main: themeColors.success,
    },
    error: {
      main: themeColors.error,
    },
    info: {
      main: themeColors.info,
    },
    text: {
      primary: '#ddd',
    },
  },
  spacing: factor => `${0.5 * factor}rem`,
  typography: {
    body2: {
      color: '#ddd',
    },
    subtitle2: {
      textTransform: 'none',
      transition: '.3s',
      color: 'rgba(231, 227, 252, 0.5)',
    },
    caption: {
      textTransform: 'none',
      transition: '.3s',
      color: 'rgba(231, 227, 252, 0.5)',
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        float: 'left',
      },
    },
    MuiAlert: {
      icon: {
        display: 'none',
      },
    },
    MuiPaper: {
      root: {
        backgroundImage: 'none',
        borderRadius: 6,
        boxShadow: '0 10px 30px 1px rgb(0 0 0 / 6%)',
      },
    },
    MuiTableSortLabel: {
      icon: {
        marginLeft: 0,
        marginRight: -8,
        fontSize: 16,
      },
    },
    MuiCssBaseline: {
      '@global': {
        color: 'red',
      },
    },
    MuiTableCell: {
      root: {
        padding: 14,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$extraSmallSize': {
          padding: '4px 8px',
          fontSize: '0.8125rem',
        },
      },
    },
  },
});

export default theme;